import React from 'react';
import styled from '@emotion/styled';
import { Link } from '@amzn/stencil-react-components/link';
import { PageHeader } from '@amzn/stencil-react-components/page';
import logo from '../images/logo/amazon_logo_RGB_WHITE.png';

const LogoImage = styled.img`
  color: #ffffff;
  height: 28px;
  width: 93px;
`;

const HomeLink = styled(Link)({
  display: 'flex',
  alignItems: 'center',
});

export default function Header() {
  return (
    <PageHeader>
      <HomeLink href="https://www.amazondelivers.jobs">
        <LogoImage alt="Amazon" src={logo} />
      </HomeLink>
    </PageHeader>
  );
}
